<template>
  <div
    class="group-meetings px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full pb-30">
        <TitleComponent class="py-4" title="Group Meetings" size="large" />
        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-4"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full pr-3">
            <p
              style="color:rgb(127, 137, 145);line-height:32px;font-size:15px;"
            >
              The ET Users Group meets annually to accomplish the objectives
              outlined in the
              <router-link class="link" to="/charter">charter</router-link>.
              Below are links to meeting proceedings where the PDF versions of
              the presentations can be viewed and downloaded.
            </p>
            <div class="flex flex-wrap">
              <div class="w-full p-4 latest-meeting">
                <router-link target="_self" to="/october-2023-meeting">
                  <div
                    class="p-6 font-bold text-center"
                    style="background-color:#f09a3e;color:white;"
                  >
                    <img
                      class="mr-1 icon-white"
                      style="display:inline-block;"
                      src="@/assets/book-solid-small.svg"
                    />
                    <i class="fa-book fas button-icon-left"></i>
                    <span class="fusion-button-text">October 2023</span>
                  </div>
                </router-link>
              </div>
              <div class="w-1/3 p-4 prior-meeting">
                <div
                  class="p-3 pt-6 pb-6 font-bold text-center"
                  style="background-color:#f4b36d;color:white;"
                >
                  <router-link target="_self" to="/october-2022-meeting">
                    <img
                      class="mr-1 icon-white"
                      style="display:inline-block;"
                      src="@/assets/book-solid-small.svg"
                    />
                    <i class="fa-book fas button-icon-left"></i>
                    <span class="fusion-button-text"
                      >October 2022</span
                    ></router-link
                  >
                </div>
              </div>
              <div class="w-1/3 p-4 prior-meeting">
                <div
                  class="p-3 pt-6 pb-6 font-bold text-center"
                  style="background-color:#f4b36d;color:white;"
                >
                  <router-link target="_self" to="/october-2021-meeting">
                    <img
                      class="mr-1 icon-white"
                      style="display:inline-block;"
                      src="@/assets/book-solid-small.svg"
                    />
                    <i class="fa-book fas button-icon-left"></i>
                    <span class="fusion-button-text"
                      >October 2021</span
                    ></router-link
                  >
                </div>
              </div>
              <div class="w-1/3 p-4 prior-meeting">
                <div
                  class="p-3 pt-6 pb-6 font-bold text-center"
                  style="background-color:#f4b36d;color:white;"
                >
                  <router-link target="_self" to="/october-2020-meeting">
                    <img
                      class="mr-1 icon-white"
                      style="display:inline-block;"
                      src="@/assets/book-solid-small.svg"
                    />
                    <i class="fa-book fas button-icon-left"></i>
                    <span class="fusion-button-text"
                      >October 2020</span
                    ></router-link
                  >
                </div>
              </div>
              <div class="w-1/3 p-4 prior-meeting">
                <div
                  class="p-3 pt-6 pb-6 font-bold text-center"
                  style="background-color:#f4b36d;color:white;"
                >
                  <router-link target="_self" to="/october-2019-meeting">
                    <img
                      class="mr-1 icon-white"
                      style="display:inline-block;"
                      src="@/assets/book-solid-small.svg"
                    />
                    <i class="fa-book fas button-icon-left"></i>
                    <span class="fusion-button-text"
                      >October 2019</span
                    ></router-link
                  >
                </div>
              </div>
              <div class="w-1/3 p-4 prior-meeting">
                <div
                  class="p-3 pt-6 pb-6 font-bold text-center"
                  style="background-color:#f4b36d;color:white;"
                >
                  <router-link target="_self" to="/october-2018-meeting">
                    <img
                      class="mr-1 icon-white"
                      style="display:inline-block;"
                      src="@/assets/book-solid-small.svg"
                    />
                    <i class="fa-book fas button-icon-left"></i>
                    <span class="fusion-button-text"
                      >October 2018</span
                    ></router-link
                  >
                </div>
              </div>
              <div class="w-1/3 p-4 prior-meeting">
                <div
                  class="p-3 pt-6 pb-6 font-bold text-center"
                  style="background-color:#f4b36d;color:white;"
                >
                  <router-link target="_self" to="/october-2017-meeting">
                    <img
                      class="mr-1 icon-white"
                      style="display:inline-block;"
                      src="@/assets/book-solid-small.svg"
                    />
                    <i class="fa-book fas button-icon-left"></i>
                    <span class="fusion-button-text"
                      >October 2017</span
                    ></router-link
                  >
                </div>
              </div>
              <div class="w-1/3 p-4 prior-meeting">
                <div
                  class="p-3 pt-6 pb-6 font-bold text-center"
                  style="background-color:#f4b36d;color:white;"
                >
                  <router-link target="_self" to="/october-2016-meeting">
                    <img
                      class="mr-1 icon-white"
                      style="display:inline-block;"
                      src="@/assets/book-solid-small.svg"
                    />
                    <i class="fa-book fas button-icon-left"></i>
                    <span class="fusion-button-text"
                      >October 2016</span
                    ></router-link
                  >
                </div>
              </div>
              <div class="w-1/3 p-4 prior-meeting">
                <div
                  class="p-3 pt-6 pb-6 font-bold text-center"
                  style="background-color:#f4b36d;color:white;"
                >
                  <router-link target="_self" to="/october-2015-meeting">
                    <img
                      class="mr-1 icon-white"
                      style="display:inline-block;"
                      src="@/assets/book-solid-small.svg"
                    />
                    <i class="fa-book fas button-icon-left"></i>
                    <span class="fusion-button-text"
                      >October 2015</span
                    ></router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-3/4 m-auto text-left mt-8 text-md">
          <p class="leading-relaxed" style="color:#7e8890;">
            Terms of Use
            <br />
            All material on this website is copyrighted by the ET Users Group™.
            By accessing the content in the Test Methods Matrix™ you agree to
            the following:
          </p>
        </div>
        <div
          style="color:#7e8890;"
          class="w-3/4 pl-16 md:pl-32 text-left ml-10 mt-4 text-md"
        >
          <ul class="list-disc">
            <li class="mt-2">
              You will not make copies of this material or otherwise make this
              content available offline for others (such as but not limited to
              creating training materials) without written consent from the ET
              Users Group;
            </li>
            <li class="mt-2">
              You will direct inquiries regarding access to this content to the
              ET Users Group;
            </li>
            <li class="mt-2">
              You will not hold liable the ET Users Group or any associated
              entity for damages caused by the use or misuse of this content.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss">
.group-meetings {
  .icon-white {
    filter: brightness(0) invert(1);
  }
}
</style>
